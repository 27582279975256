exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-templates-content-card-template-content-card-template-tsx": () => import("./../../../src/templates/ContentCardTemplate/ContentCardTemplate.tsx" /* webpackChunkName: "component---src-templates-content-card-template-content-card-template-tsx" */),
  "component---src-templates-content-cards-page-template-content-cards-page-template-tsx": () => import("./../../../src/templates/ContentCardsPageTemplate/ContentCardsPageTemplate.tsx" /* webpackChunkName: "component---src-templates-content-cards-page-template-content-cards-page-template-tsx" */)
}

